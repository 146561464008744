import React from 'react';
import PropTypes from 'prop-types';
import { CollecticonBrandDevelopmentSeed } from '@devseed-ui/collecticons';

// How to add a new sandbox page:
// Follow the instructions on ./_layout.js

import Constrainer from '../../styles/constrainer';
import Prose from '../../styles/typography/prose';
import SandboxLayout from './_layout';

const SandboxProse = ({ location }) => {
  return (
    <SandboxLayout location={location} title='Sandbox'>
      <Constrainer>
        <Prose>
          <h2>xlarge title (h2)</h2>
          <h3>large title (h3)</h3>
          <h4>medium title (h4)</h4>
          <h5>small title (h5)</h5>
          <h6>xsmall title (h6)</h6>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ornare
            nulla risus, quis faucibus magna lobortis in. Nam non tincidunt
            sapien. Vestibulum ante ipsum primis in faucibus orci luctus et
            ultrices posuere cubilia curae; Vivamus varius, massa ac viverra
            ultricies, eros sem tempor diam, eu vehicula mi libero et nulla.
          </p>

          <p>
            Curabitur ac porta enim. Aliquam volutpat tortor ultrices risus
            tincidunt, et finibus arcu blandit. Nullam eget scelerisque lectus.
            Aenean pellentesque sem ut sem consequat tincidunt. Nulla auctor
            ante sit amet fringilla semper. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Maecenas dictum sodales mollis.
            Pellentesque at hendrerit lacus, nec iaculis tortor. Pellentesque id
            turpis consequat, scelerisque ligula at, scelerisque odio. Sed
            consectetur mauris et ante scelerisque tempus. Sed a arcu nec purus
            maximus luctus. Maecenas nec magna felis.
          </p>

          <ul>
            <li>Lorem ipsum dolor sit amet</li>
            <li>
              Nulla volutpat aliquam velit
              <ul>
                <li>Phasellus iaculis neque</li>
                <li>Ac tristique libero volutpat at</li>
              </ul>
            </li>
            <li>Faucibus porta lacus fringilla vel</li>
            <li>Aenean sit amet erat nunc</li>
          </ul>

          <ol>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Consectetur adipiscing elit</li>
            <ol>
              <li>Phasellus iaculis neque</li>
              <li>Purus sodales ultricies</li>
            </ol>

            <li>Faucibus porta lacus fringilla vel</li>
            <li>Aenean sit amet erat nunc</li>
          </ol>

          <dl>
            <dt>Description list term</dt>
            <dd>Description list description.</dd>
            <dt>Euismod lorem ipsum dolor sit amet</dt>
            <dd>
              Vestibulum id ligula porta felis euismod semper eget lacinia odio
              sem nec elit.
            </dd>
            <dd>Donec id elit non mi porta gravida at eget metus.</dd>
            <dd>Third.</dd>
            <dt>Malesuada porta</dt>
            <dd>Etiam porta sem malesuada magna mollis euismod.</dd>
            <dt>Yet another term</dt>
            <dd>
              Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum
              nibh, ut fermentum massa justo sit amet risus.
            </dd>
          </dl>

          <p>
            Lorem ipsum dolor sit amet and a loose icon{' '}
            <CollecticonBrandDevelopmentSeed />
          </p>
        </Prose>
      </Constrainer>
    </SandboxLayout>
  );
};

export default SandboxProse;

SandboxProse.propTypes = {
  location: PropTypes.object
};
